<template>
  <pre class="my-app">
    <a href="https://github.com/gridonic/generator">@gridonic/generator</a> was here.
  </pre>
</template>

<script>
    export default {
        name: 'App',
    };
</script>

<style lang="scss" scoped>
    .my-app {
        align-items: center;
        display: flex;
        font-family: sans-serif;
        font-size: 2rem;
        height: 50vh;
        justify-content: center;
        line-height: 1;

        a {
            color: #09e;
            text-decoration: none;
        }
    }
</style>
